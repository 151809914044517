.root {
  background-color: var(--white);
  border-radius: 16px;
  padding: 16px;
  position: relative;
  display: flex;
  gap: 16px;
  transition: 0.2s all ease-in-out;
  justify-content: space-between;
  width: 100%;
  box-shadow: 4px 40px 40px -40px rgba(88, 91, 227, 0.3);
}

.icon {
  background-color: var(--primary-color);
  color: white;
  border-radius: 5px;
  display: grid;
  width: 40px;
  height: 40px;
  font-size: 25px;
  justify-items: center;
  align-items: center;
}

.loadingGhost .icon,
.loadingGhost .name {
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  background-size: 200% 100%;
  animation: 1s shine linear infinite;
  color: transparent;
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}

.left {
  display: grid;
  grid-template-columns: 40px 1fr;
  column-gap: 10px;
  align-items: center;
}

.right {
  display: flex;
  gap: 10px;
}
