.canvas {
  width: auto;
  max-width: 100%;
  max-height: 100%;
  height: 100%;
}

.canvasWrapper {
  height: calc(100vh - 250px);
  text-align: center;
  width: 100%;
  background-color: var(--gray-100);
  border-radius: 10px;
  overflow: hidden;
}

.thumbnails {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 5px;
  grid-row-gap: 5px;
}

.frame {
  border-radius: 5px;
  width: 100%;
  height: auto;
}

.root {
  display: flex;
  flex-direction: column;
  gap: 7px;
}

.twoButtons {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.loadingGhost .canvas {
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  background-size: 200% 100%;
  animation: 1s shine linear infinite;
  color: transparent;
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}

.playControls {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  border: 1px solid var(--gray-200);
  border-radius: 10px;
  padding: 5px;
}

.playBackInnerControls {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
}

.fadeIcon {
  color: gray;
}

.playControlButton {
  background: none;
  border: none;
  padding: 5px;
  cursor: pointer;
}

.speedController > div {
  border: none !important;
  cursor: pointer;
}

.speedController {
  cursor: pointer;
  min-width: 114px;
}

.playControlButton:disabled,
.disabled {
  pointer-events: none;
  color: var(--gray-400);
}
