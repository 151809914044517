.root {
  display: grid;
  grid-template-columns: repeat(3, minmax(300px, 1fr));
  gap: 20px;
  width: 100%;
}

@media screen and (max-width: 980px) {
  .root {
    grid-template-columns: repeat(2, minmax(300px, 1fr));
  }
}

@media screen and (max-width: 680px) {
  .root {
    grid-template-columns: repeat(1, minmax(300px, 1fr));
  }
}
