.root {
  display: inline-grid;
  width: 100%;
  max-width: 400px;
  background-color: var(--gray-50);
  padding: 10px 15px;
  border-radius: 10px;
  border: 1px solid var(--gray-100);
  grid-template-columns: 16px 1fr;
  gap: 5px;
}

.icon svg {
  float: left;
}

.icon {
  margin-top: 3.3px;
}

.root.error {
  background-color: var(--error-100);
  border-color: var(--error-500);
  color: var(--error-500);
}
