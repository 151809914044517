.pagination {
  list-style-type: none;
  padding-inline-start: 0;
  display: flex;
  gap: 1px;
  flex-direction: row;
  align-items: center;
}

.page,
.prev,
.next {
  color: var(--primary-color);
  transition: 0.2s all ease-in-out;
  border: 1px solid transparent;
  border-radius: 8px;
  min-height: 35px;
  min-width: 35px;
  display: inline-grid;
  padding: 0 10px 1px;
  justify-items: center;
  align-items: center;
  cursor: pointer;
}

.prev svg {
  float: left;
  margin-left: -1px;
}

.next svg {
  float: left;
}

.active .page,
.page:hover,
.prev:hover,
.next:hover {
  background-color: white;
  border-color: #e5e6ff;
}

.hide {
  display: none;
}

.prev,
.next {
  cursor: pointer;
}
