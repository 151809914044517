.root {
  border-radius: 16px;
  background-color: var(--white);
  margin-top: 2%;
}

.fullWidthCard {
  width: 100%;
  background-color: var(--white);
  border-radius: 16px;
  padding: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;
  transition: 0.2s all ease-in-out;
}

.sharedWithContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.sharedWithRowContainer:not(:last-child) {
  border-bottom: 1px solid var(--gray-100);
  padding-bottom: 8px;
}

.sharedWithRowContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sharedWithRow {
  display: inline-grid;
  gap: 20px;
  grid-template-columns: 200px 5px minmax(200px, auto) 5px 1fr;
  align-items: center;
  justify-content: start;
}

.sharedWithColumn {
  padding: 4px;
  text-align: left;
}

.sharedWithColumnButton {
  text-align: right;
  display: flex;
}

.descriptionText {
  font-family: 'Lexend', sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #626e8e;
}

.smallText {
  font-family: 'Lexend', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #626e8e;
}

.icon {
  width: 16px;
  height: 16px;
  margin-right: 8px;
  vertical-align: middle;
}

.iconTime {
  width: 16px;
  height: 16px;
  margin-right: 8px;
  margin-top: 4%;
}

.bullet {
  width: 5px;
  height: 5px;
  border-radius: 100px;
  background-color: var(--gray-200);
}

.cardTitle {
  margin: 0;
}

.loadingGhost,
.loadingGhost:disabled {
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  background-size: 200% 100%;
  animation: 1s shine linear infinite;
  color: transparent;
  border-color: transparent;
  pointer-events: none;
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}

.descriptionInner {
  font-weight: 300;
  font-size: 14px;
}

.descriptionInner svg {
  margin-bottom: -2px;
}
