.root {
  border-radius: var(--base-border-radius);
  border: 1px solid var(--success-400);
  color: var(--success-400);
  background-color: var(--success-100);
  font-size: 14px;
  padding: 6px 10px;
  display: inline-block;
  font-weight: 400;
}

.root.share {
  color: var(--blue-400);
  border-color: var(--blue-400);
  background-color: var(--blue-50);
}

.root.release {
  color: #32d583;
  border-color: #32d583;
  background-color: #d1fadf;
}

.root.revoke {
  color: var(--error-400);
  border-color: var(--error-400);
  background-color: var(--error-100);
}

.root.small {
  padding: 2px 5px;
}

.root.loadingGhost {
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  background-size: 200% 100%;
  animation: 1s shine linear infinite;
  color: transparent;
  border: none;
  border-radius: 0;
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}
