.root {
  background-color: var(--white);
  padding: 16px;
  border-radius: 8px;
}

.root .inner {
  display: grid;
  grid-template-columns: 46px 1fr 24px;
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  align-items: start;
  gap: 16px;
}

.icon {
  height: 46px;
  display: grid;
  background-color: var(--success-400);
  border-radius: 4px;
  justify-items: center;
  align-items: center;
}

.icon svg path {
  fill: var(--primary-800);
}

.size {
  font-size: 14px;
}

.remove svg path {
  fill: var(--gray-500);
}

.remove {
  cursor: pointer;
}

.error {
  color: var(--error-500);
  margin: 0;
}

.errorFile {
  background-color: var(--error-100);
}

.errorFile .icon {
  background-color: var(--error-400);
}

.name {
  word-break: break-all;
}
