.header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.user {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;

  font-size: 24px;
  line-height: 44px;
  font-weight: 500;
  color: var(--gray-800);
}

.user > p {
  margin: 0;
}

.insitution {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;

  font-size: 16px;
  line-height: 20px;
  color: var(--gray-800);
  font-weight: 400;
}
