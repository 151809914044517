.layoutContainer {
  height: 100vh;
  transition: 0.2s all ease-in-out;
  padding-left: 480px;
}

@media screen and (max-width: 1000px) {
  .layoutContainer {
    grid-template-columns: 350px 1fr;
  }
}

@media screen and (max-width: 830px) {
  .layoutContainer {
    grid-template-columns: 300px 1fr;
  }
}

.sidebar {
  height: 100%;
  background-image: url('../../assets/loginBackground.jpg');
  background-repeat: no-repeat;
  background-position: right;
  background-size: cover;
  position: relative;
  display: grid;
  align-items: center;
  justify-items: center;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  width: 480px;
}

.inner {
  text-align: center;
  color: var(--white);
}

.logo {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  justify-items: center;
}

.inner p {
  font-size: 18px;
  margin: 0;
}

.content {
  position: relative;
  max-width: 420px;
  margin: auto auto;
  width: 100%;
  padding-top: 87.5px;
  padding-bottom: 87.5px;
  height: 100vh;
}

.backButton {
  position: absolute;
  left: -50px;
  top: 50px;
}
