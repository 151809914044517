.header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.user {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  font-size: 24px;
  line-height: 44px;
  font-weight: 500;
  color: var(--gray-800);
  
  margin: 0;
}
