.root {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.root.withLateralContent {
  justify-content: space-between;
}

.wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}

.filterBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  position: relative;
}

.filterTopPlaceholder {
  font-size: 14px;
  line-height: 1;
  font-weight: 500;
  color: var(--gray-400);
  text-align: left;
  position: absolute;
  left: 0;
  bottom: calc(100% + 5px);
}

.input {
  padding: 16.3px 16px;
}

.select {
  height: 100%;
}
