.root {
  width: 100%;
  background-color: var(--white);
  border-radius: 16px;
  padding: 16px;
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  justify-content: space-between;
}

.section {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.section.patientInfo {
  display: grid;
  gap: 20px;
  grid-template-columns: 400px 75px 1fr;
  grid-template-rows: 1fr;
}

.name {
  font-weight: 600;
  text-decoration: none;
  color: inherit;
}

.label {
  color: var(--gray-400);
  font-weight: 400;
}

.email {
  font-weight: 400;
}

.cell {
  display: flex;
  gap: 5px;
  align-items: center;
}

.loadingGhost {
  pointer-events: none;
}

.loadingGhost .cell {
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  background-size: 200% 100%;
  animation: 1s shine linear infinite;
  color: transparent;
  min-width: 100px;
  min-height: 25px;
}

.loadingGhost .cell:first-child {
  min-width: 150px;
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}
