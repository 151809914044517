.flag {
  color: var(--primary-color);
  background-color: var(--primary-100);
  border: 1px solid var(--primary-color);
  cursor: pointer;
  width: 22px;
  text-align: center;
  border-radius: 5px;
  display: inline-block;
}

.root {
  display: block;
  float: right;
}

.tooltipRoot {
  font-weight: 400;
}
