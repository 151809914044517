.title {
  font-size: 24px;
  line-height: 32px;
  font-weight: 400;
  margin-bottom: 25px;
}

.subtitle {
  font-size: 18px;
  line-height: 16px;
  font-weight: 400;
  margin-bottom: 25px;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.buttons {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.uploadedInfo {
  margin: -10px 0 20px;
  color: var(--gray-500);
}

.stepStatus {
  width: 40px;
  height: 40px;
  border: 2px solid var(--gray-100);
  display: grid;
  border-radius: 50%;
  overflow: hidden;
  justify-items: center;
  align-items: center;
  transition: 0.2s all ease-in-out;
  position: relative;
}

.stepStatus.loading {
  border-color: transparent;
}

.stepStatus.loading::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 2px solid var(--secondary-400);
  border-top-color: transparent;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.minHeight {
  min-height: 64px;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.stepStatus.finished {
  color: white;
  border-color: var(--success-400);
  background-color: var(--success-400);
}

.stepStatus.finished .stepIcon {
  margin-top: 2px;
}

.stepStatus:not(.finished) .stepIcon {
  margin-bottom: 2px;
}

.progress {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.step {
  display: flex;
  flex-direction: row;
  gap: 10px;
  border: 1px solid var(--gray-100);
  padding: 16px;
  border-radius: 16px;
}

.stepInfo p {
  margin: 0;
  color: var(--gray-500);
  font-size: 14px;
}

.stepInfo {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  width: calc(100% - 56px);
}

.step.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.hash {
  font-size: 14px;
  word-break: break-all;
}

.formError {
  background-color: var(--error-400);
  padding: 5px 10px;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
}

.fieldsGroup {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.groupTitle {
  margin-bottom: 10px;
}

.group {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.groupStudy {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.succesWrapper {
  text-align: center;
  width: 100%;
  max-width: 420px;
  margin: 150px auto 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* .succesWrapper svg {
  margin-bottom: 20px;
} */

.succesWrapper h1 {
  margin: 0;
}

.succesWrapper p {
  margin: 0;
  color: var(--gray-600);
}

.loadingBar {
  width: 100%;
  margin-top: 2px;
  display: grid;
  grid-template-columns: 1fr 36px;
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  align-items: center;
  gap: 17px;
}

.fullBar {
  width: 100%;
  min-height: 6px;
  border-radius: 16px;
  background-color: var(--gray-200);
  position: relative;
}

.state {
  height: 6px;
  border-radius: 16px;
  background-color: var(--secondary-400);
}

.percentage {
  color: var(--gray-600);
  font-size: 14px;
  text-align: right;
}
