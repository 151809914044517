.root {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.link {
  padding: 28px 0;
  color: var(--gray-400);
  text-decoration: none;
  border-bottom: 2px solid transparent;
  transition: 0.2s all ease-in-out;
  font-weight: 400;
}

.link:hover,
.link.active {
  color: var(--gray-800);
  border-bottom: 2px solid var(--secondary-400);
}
