.root {
  display: flex;
  gap: 30px;
  flex-direction: column;
}

.title {
  font-size: 36px;
  font-weight: 500;
  margin: 0;
}

.inner.flexSpacing {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: flex-start;
}

.inner.flexSpacing.flexColumnOrientation {
  flex-direction: row;
}
