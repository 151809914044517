.root {
  display: flex;
  flex-direction: row;
  min-height: 100%;
  width: 100%;
}

.left,
.right {
  width: 50%;
  text-align: center;
  padding: 150px 50px 100px;
}

.left {
  background-color: var(--white);
}

.right {
  background-color: var(--white-shade);
}

@media screen and (min-width: 1400px) {
  .left {
    text-align: right;
  }

  .right {
    text-align: left;
  }
}

.inner {
  width: 100%;
  max-width: 500px;
  text-align: left;
  display: inline-block;
}
