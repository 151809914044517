body .section {
  width: 105%;
  padding: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

body .section > div {
  height: 100%;
}

body .section > div > div > div > div {
  gap: 48px;
}

body .row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 8px;
}

.button {
  width: 100%;
}

body .body {
  gap: 48px;
}
