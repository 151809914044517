.root {
  background-color: var(--white);
  border-radius: 16px;
  padding: 25px;
  box-shadow: 4px 40px 40px -40px rgba(88, 91, 227, 0.3);
  display: flex;
  flex-direction: column;
  gap: 15px;
  position: relative;
  overflow: hidden;
}

.title,
.contents,
.buttons {
  position: relative;
}

.title {
  margin: 0;
  line-height: 1.2;
}

.contents p,
.contents ul {
  margin: 0;
}

.contents p:not(:last-child),
.contents ul:not(:last-child) {
  margin-bottom: 10px;
}

.contents ul {
  gap: 2px;
}

.contents ul li a {
  text-decoration: none;
  color: inherit;
}

.contents strong {
  font-weight: 700;
}
