.loadingBar {
  width: 100%;
  margin-top: 2px;
  display: grid;
  grid-template-columns: 1fr 36px;
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  align-items: center;
  gap: 17px;
}

.fullBar {
  width: 100%;
  min-height: 6px;
  border-radius: 16px;
  background-color: var(--gray-200);
  position: relative;
}

.state {
  height: 6px;
  border-radius: 16px;
  background-color: var(--secondary-400);
}

.percentage {
  color: var(--gray-600);
  font-size: 14px;
  text-align: right;
}
