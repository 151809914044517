.loadingCircle circle {
  transition: stroke-dashoffset 0.5s;
  transform: rotate(0);
  transform-origin: 50% 50%;
  stroke: var(--primary-color);
}

.loadingCircle .indefiniteAnimation {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
