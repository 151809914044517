.root {
  display: flex;
  flex-direction: column;
  gap: 32px;
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
}

.encriptedPlaceholder {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  color: var(--gray-600);
}

.statusInfoTooltipInner {
  max-width: 200px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
}

.statusInfoTooltipInner h3 {
  margin: 0 0 10px;
}

.statusInfoTooltipInner > div:not(:last-child) {
  margin-bottom: 5px;
}

.dateSvg,
.physicianSvg {
  margin-bottom: -2px;
}

.studyName {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
}

.studyName span {
  font-weight: 700;
}
