.root {
  background-color: var(--white);
  box-shadow: var(--shadow-xs);
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  z-index: 5;
}

.inner,
.menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.menu {
  gap: 20px;
}

.brandMark path {
  fill: var(--primary-800);
}

.brandName svg {
  float: left;
}

.brandName svg path {
  fill: var(--gray-900);
}

.medicalUnit {
  color: var(--primary-800);
  font-size: 12px;
  line-height: 1;
  font-weight: 400;
  margin-top: 2px;
}

.logo {
  display: flex;
  gap: 15px;
  align-items: center;
  flex-direction: row;
  text-decoration: none;
}

.brandWrapper {
  display: flex;
  gap: 1px;
  flex-direction: column;
}
