.signInWrapper {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.signInForm p {
  font-weight: 400;
  font-size: 16px;
  color: var(--light-gray);
}

.formTitle {
  font-weight: 500;
  font-size: 30px;
  color: var(--dark-gray);

  text-align: center;
  margin-bottom: 1.5em;
}

.formTitle {
  text-align: center;
  margin: 0;
  font-weight: 400;
  font-size: 36px;
}

.formTitle > span {
  font-weight: 700;
}

.signInForm {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.signInForm p {
  margin: 0;
  font-weight: 300;
}

.signInForm > p > span > a {
  color: var(--primary-color);
  text-underline-offset: 2px;
}

p.error {
  background-color: var(--error-400);
  padding: 5px;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  color: var(--gray-800);
  text-align: center;
}

.centerSvg {
  margin: 0 auto 24px auto;
}

.error {
  background-color: var(--error-400);
  padding: 5px;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  color: var(--gray-800);
  text-align: center;
}

body .resetButton {
  padding: 1px;
  font-size: 12px;
  line-height: 15px;
  font-weight: 400;
  cursor: pointer;
  border: 0;
  color: var(--gray-500);
  transition: 0.2s all ease-in-out;
}

body .resetButton:hover {
  border: 0;
  font-weight: 500;
  color: var(--gray-500)!important;
  background-color: transparent!important;
  cursor: pointer;
  transition: 0.2s all ease-in-out;
}

.link {
  color: var(--light-gray);
}

.newAccountWrapper {
  text-align: center;
  margin-top: 20px;
}

.title {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  line-height: 44px;
  font-weight: 700;
  color: var(--primary-color);
  margin: 24px 0;
}

.line {
  flex-grow: 1;
  height: 2px;
  background-color: var(--primary-color);
  margin: 0;
}

.newAccountButton {
  width: 100%;
}

.formFields {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.titleText {
  margin: 0 10px;
}

.backButton {
  position: absolute;
  left: -40px;
  top: -40px;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 8px;
}

body .resendButton {
  color: white;
}

body .resendButton > span {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.centralError {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.resentText {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  gap: 4px;

  text-align: center;
}

body .resendButtonValidate {
  color: white;
  display: inline-block;
}
