.root {
  display: grid;
  grid-template-columns: 160px 1fr 125px;
  grid-column-gap: 10px;
  grid-row-gap: 0px;
}

.permissions {
  text-align: center;
}

.type {
  text-align: right;
}

.description span {
  color: var(--gray-400);
}

.description a {
  color: inherit;
}

.separator {
  width: 100%;
  border: none;
  background: var(--gray-100);
  height: 1px;
  margin: 0;
}

.timestamp {
  display: flex;
  gap: 5px;
  flex-wrap: nowrap;
  flex-direction: row;
}

.timestamp svg {
  margin-top: 2px;
}

.loadingGhost .timestamp,
.loadingGhost .description {
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  background-size: 200% 100%;
  animation: 1s shine linear infinite;
  color: transparent;
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}
