.root {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 10px;
  border: 1px solid transparent;
  border-radius: 8px;
  gap: 5px;
}

.processing {
  border-color: #339cd8;
  color: #339cd8;
  background-color: #cdedff;
}

.error {
  border-color: #e05f36;
  color: #e05f36;
  background-color: #ffeded;
}

.completed {
  border-color: #32d583;
  color: #32d583;
  background-color: #d1fadf;
}

.active {
  border-color: #32d583;
  color: #32d583;
  background-color: #d1fadf;
}

.expired {
  border-color: var(--gray-500);
  color: var(--gray-500);
  background-color: var(--gray-100);
}

.label {
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
}

.icon {
  width: 16px;
  height: 16px;
}

.processing .icon svg {
  animation: spin 2s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
