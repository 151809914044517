.signUpForm p {
  font-weight: 400;
  font-size: 16px;
  color: var(--light-gray);
}

.formTitle {
  font-weight: 500;
  font-size: 30px;
  color: var(--dark-gray);

  text-align: center;
  margin-bottom: 1.5em;
  width: 100%;
  white-space: nowrap;
}

.formTitle {
  text-align: center;
  margin: 0;
  font-weight: 400;
  font-size: 36px;
}

.formTitle > span {
  font-weight: 700;
}

.signUpForm {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-bottom: 87px;
}

.signUpForm p {
  margin: 0;
  font-weight: 300;
}

.signUpForm > p > span > a {
  color: var(--primary-color);
  text-underline-offset: 2px;
}

p.erorr {
  background-color: var(--error-400);
  padding: 5px;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  color: var(--gray-800);
  text-align: center;
  display: flex;
  flex-direction: column;
}

div.erorr {
  background-color: var(--error-400);
  padding: 5px;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  color: var(--gray-800);
  text-align: center;
}

.error {
  background-color: var(--error-400);
  padding: 2px 5px;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
}

.tabs {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: 10px;
}

.inputRow {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 15px;
}

.checkboxWrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
  width: fit-content;
}

.checkboxInput {
  border: 1px solid var(--gray-100);
  padding: 12px 16px;
  border-radius: var(--base-border-radius);
  font-weight: 400;
  transition: 0.2s all ease-in-out;
  margin-top: 6px;
}

.succesWrapper {
  text-align: center;
  width: 100%;
  max-width: 420px;
  margin: 150px auto 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 0;
  align-items: center;
}

.reviewWrapper {
  text-align: center;
  width: 100%;
  max-width: 420px;
  margin: 150px auto 0;
  display: flex;
  flex-direction: column;
  gap: 48px;
  margin-top: 0;
  align-items: center;
}

.reviewWrapper h2 {
  margin: 0;
}

.reviewWrapper p {
  margin: 0;
}

.reviewWrapper div {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.forgotPassword {
  margin: 0;
}

body .confirmScreenSection {
  width: 105%;
  padding: 0;
}

body .confirmScreenSectionBtn {
  width: 105%;
  padding: 0;
}

.confirmWrapper {
  text-align: left;
  width: 100%;
  max-width: 420px;
  margin: 150px auto 0;
  display: flex;
  flex-direction: column;
  gap: 48px;
  margin-top: 0;
  align-items: center;
  text-wrap: wrap;
}

.confirmWrapper h2 {
  font-weight: 500;
  font-size: 36px;
  line-height: 44px;
  color: var(--dark-gray);

  text-align: left;
  width: 100%;
  white-space: wrap;
}

.confirmMessageBody {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.link {
  color: var(--light-gray);
}

body .row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 8px;
}

body .resendButton {
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

body .resendButtonValidate {
  color: white;
  display: inline-block;
}

body .resendButton > span {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.back {
  position: absolute;
  left: -100px;
  top: 50px;
}
