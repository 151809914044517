.root {
  border: 1px dashed var(--gray-100);
  border-radius: 5px;
  padding: 10px;
  display: grid;
  grid-template-columns: 16px 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 10px;
  color: var(--gray-600);
}

.icon {
  margin-top: 2px;
  color: var(--button-navy-blue);
}
