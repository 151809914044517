.root {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 6px;
  align-items: flex-start;
}

.input {
  border: 1px solid var(--gray-100);
  padding: 12px 16px;
  border-radius: var(--base-border-radius);
  font-weight: 400;
  width: 100%;
  min-height: 48px;
  transition: 0.2s all ease-in-out;
}

.flagDropdown {
  background: #FFFFFF;
  border-color: var(--gray-100);
  min-width: 400px;
}

.codeInputWrapper {
  display: flex;
  justify-content: space-between;
  padding-left: 0;
  padding-right: 0;
}

.input:focus {
  background-color: var(--gray-100);
  outline: none;
}

.label {
  font-weight: 500;
  font-size: 14px;
  color: var(--gray-700);
}

.label sup {
  color: var(--success-400);
}

.error {
  background-color: var(--error-400);
  padding: 2px 5px;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
}

.codeInput {
  border: none;
  border-radius: 0;
  outline: none !important;
  text-align: center;
}

.codeInput:not(:last-child) {
  border-right: 1px solid var(--gray-100);
}

.codeInput::placeholder {
  font-weight: 300;
  color: var(--gray-100);
}

.inputWrapper {
  width: 100%;
  position: relative;
}

.toggleShowText {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  height: 100%;
  padding: 5px 10px;
  font-size: 20px;
  cursor: pointer;
  transition: 0.2s all ease-in-out;
  color: var(--gray-400);
}

.phoneInputContainer .form-control {
  border: 1px solid #ccc; /* Default border color */
}

body .phoneInputContainer .form-control:hover {
  border: 1px solid #ccc; /* Same border color on hover to remove the hover effect */
  border-color: #ccc;
}

@media screen and (min-width: 750px) {
  .toggleShowText:hover {
    color: var(--primary-color);
  }
}

.toggleShowText svg {
  float: left;
}

.inputWrapper.withIcon {
  position: relative;
}

.inputWrapper.withIcon .input {
  padding-left: 40px;
}

.icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
  line-height: 0;
}

.select {
  padding: 4px 16px;
}
