.root {
  height: 100%;
  min-height: 100vh;
  background: var(--white-shade);
  /* background-image: radial-gradient(#ccd0dc 1px, transparent 0); */
  /* background-size: 20px 20px; */
  /* background-position: -20px -20px; */
  box-sizing: border-box;
}

.root:not(.noRows) {
  padding-top: 120px;
}

.wrapper {
  display: flex;
  min-height: 100vh;
}

.root:not(.noRows) .wrapper {
  gap: 80px;
  flex-direction: column;
  padding-bottom: 80px;
}
