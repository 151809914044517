.title {
  font-weight: 400;
  font-size: 36px;
  color: var(--dark-gray);

  text-align: center;
  width: 100%;
  white-space: nowrap;
  margin: 0;
  font-weight: 400;
  font-size: 36px;
}

.title > span {
  font-weight: 700;
}

.succesWrapper {
  text-align: center;
  width: 100%;
  max-width: 420px;
  margin: 150px auto 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  margin-top: 0;
  align-items: center;
}


body .confirmWrapper {
  text-align: left;
  width: 110%;
  height: 100%;
  max-width: 420px;
  margin: 150px auto 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 24px;
  margin-top: 0;
  text-wrap: wrap;
}


body .confirmWrapper h2 {
  font-weight: 500;
  font-size: 36px;
  line-height: 44px;
  color: var(--dark-gray);

  text-align: left;
  width: 100%;
  white-space: wrap;
}

.wrapper {
  position: relative;
  text-align: center;
  width: 100%;
  max-width: 420px;
  margin: 150px auto 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 48px;
  margin-top: 0;
  align-items: center;
}

.errorWrapper {
  text-align: center;
  width: 100%;
  max-width: 420px;
  margin: 150px auto 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 48px;
  margin-top: 0;
  align-items: center;
}

.errorWrapper p {
  margin: 0;
}

.wrapper h2 {
  margin: 0;
}

.wrapper p {
  margin: 0;
}

.wrapper div {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.forgotPassword {
  margin: 0;
}

body .confirmScreenSection {
  width: 105%;
  padding: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

body .confirmScreenSection > div {
  height: 100%;
}

body .confirmScreenSectionBtn {
  width: 105%;
  padding: 0;
  margin-top: 24px;
}


.confirmMessageBody {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.loadingWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

body .width100 {
  width: 100%;
}

.countdown {
  color: var(--gray-500);
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
}

.backButton {
  position: absolute;
  left: -100px;
  top: -35px;
}

body .row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 8px;
}
