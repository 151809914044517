.inner {
  display: inline-block;
  position: relative;
  line-height: 0;
}

.inner:after {
  content: ' ';
  display: block;
  margin: 0;
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  border-radius: 50%;
  border: 0.4em solid var(--primary-800);
  border-color: var(--primary-800) transparent var(--primary-800) transparent;
  animation: animation 0.7s linear infinite;
}

.inner.white:after {
  border-color: var(--white) transparent var(--white) transparent;
}

@keyframes animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
