.root {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 25px;
}

.leftColumn {
  background-color: var(--white);
  border-radius: 16px;
  padding: 25px;
  box-shadow: 4px 40px 40px -40px rgba(88, 91, 227, 0.3);
}

.rightColumn {
  display: flex;
  gap: 25px;
  flex-direction: column;
}

.importStudiesBox {
  padding-top: 80px;
}

.importStudiesIcon {
  position: absolute;
  top: -100px;
  left: -10px;
}

.supportBoxIcon {
  color: var(--gray-100);
  position: absolute;
  top: -60px;
  right: -40px;
}

.confirmText {
  text-align: start;
  justify-content: flex-start;
}
