.root {
  background: none;
  padding: 0;
  border: none;
  display: flex;
  align-items: center;
  gap: 4px;
  color: var(--gray-500);
  cursor: pointer;
  transition: 0.2s all ease-in-out;
}

.root span {
  font-size: 14px;
  margin-top: -4px;
}

.icon svg path {
  stroke: var(--gray-500);
  transition: 0.2s all ease-in-out;
}

.root:hover .icon svg path {
  stroke: var(--gray-800);
}

.root:hover {
  color: var(--gray-800);
}
