.form {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
}

.errorMessage {
  background-color: var(--error-400);
  padding: 5px 10px;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
}

.rotating {
  animation: spin 2s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.revokeModalContents {
  margin: 20px 0;
}

.revokeModalContents p {
  margin: 0;
  color: var(--gray-700);
}

.revokeModalContents p:not(:last-child) {
  margin-bottom: 6px;
}
