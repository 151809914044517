.inner,
.value {
  display: flex;
  gap: 5px;
  align-items: center;
}

.root {
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: flex-start;
}

.value {
  font-weight: 400;
  word-break: break-all;
}

.inner {
  width: 100%;
  display: grid;
  grid-template-columns: 200px 1fr;
  gap: 10px;
}

.input {
  border-radius: 0;
  border: none;
  border-bottom: 1px solid var(--light-gray);
  outline: none !important;
}

.btn {
  transition: 0.2s all ease-in-out;
  cursor: pointer;
}

.btn:hover {
  scale: 1.1;
  color: var(--button-navy-blue);
}

.error {
  background-color: var(--error-400);
  padding: 2px 5px;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
}

.label {
  max-width: 200px;
}
