.root {
  border: 1px solid var(--form-input-border);
  position: relative;
  height: 48px;
  width: 48px;
  border-radius: 30px;
  position: relative;
  margin: 17px 0;
}

.innerIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.innerIcon svg {
  float: left;
  fill: var(--primary-800);
}

.innerIcon svg path {
  fill: var(--primary-800);
}

.subMenu {
  display: none;
  position: absolute;
  top: calc(100% + 25px);
  right: 0;
  width: 260px;
  background-color: var(--white);
  box-shadow: 0 5px 24px -4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.root:hover .subMenu {
  display: block;
}

.subMenu:before {
  content: '';
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid var(--white);
  position: absolute;
  bottom: 100%;
  right: 20px;
}

.subMenu:after {
  content: '';
  width: 100%;
  height: 40px;
  position: absolute;
  bottom: 100%;
  right: 0;
  left: 0;
}

.link {
  padding: 12px 16px;
  display: flex;
  text-decoration: none;
  color: var(--gray-400);
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.separator {
  width: 100%;
  border: none;
  background: var(--gray-100);
  height: 1px;
  margin: 0;
}

.buttonOverride {
  border: none;
  background: none;
  cursor: pointer;
}
