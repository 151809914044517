.downloading {
  text-align: center;
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  background-size: 200% 100%;
  animation: 1s shine linear infinite;
  padding: 100px 20px;
  border-radius: 15px;
  min-height: 300px;
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}

.downloadInfo {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}

.percentageBar {
  width: 100%;
  background-color: #b6b6b6;
  max-width: 300px;
  margin: 0 auto;
  border-radius: 100px;
}

.innerPercentageBar {
  height: 20px;
  background-color: var(--primary-color);
  max-width: 100%;
  border-radius: 100px;
}

.dicomTitle {
  margin-top: -15px;
  margin-bottom: 5px;
}

.dicomTitle span {
  transition: 0.2s all ease-in-out;
  cursor: pointer;
  border-radius: 2px;
  padding: 0 1px;
}

.dicomTitle span:hover {
  background-color: var(--gray-400);
}
