.root {
  border: 1px dashed var(--gray-200);
  padding: 5px;
  border-radius: 8px;
  width: 100%;
}

.root.dragging {
  border-color: var(--secondary-400);
}

.wrapper {
  height: 400px;
  display: grid;
  background-color: var(--white);
  padding: 0 50px;
  justify-items: center;
  align-items: center;
  border-radius: 4px;
  transition: 0.2s all ease-in-out;
}

.dragging .wrapper {
  background-color: var(--white-shade);
}

.inner {
  text-align: center;
  color: var(--gray-600);
}

.inner p span {
  color: var(--secondary-400);
  text-decoration: underline;
  cursor: pointer;
}

.fileAdded .wrapper {
  height: 230px;
}

.fileList {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.icon {
  cursor: pointer;
}
