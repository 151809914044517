.root {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.date {
  font-size: 16px;
  line-height: 20px;
}

.time {
  font-size: 14px;
  line-height: 16px;
  font-weight: 300;
}
