.root {
  max-width: 655px;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  background-color: #ffffff;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}

.root:before {
  position: absolute;
  content: '';
  background: var(--white-shade);
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
}

.rootLoading {
  margin: 100px auto 0;
}

.rootPdf {
  position: relative;
}

.pdfDownload {
  max-width: 420px;
  text-align: center;
  margin: 40px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header {
  width: 100%;
  height: 149px;
  position: relative;
  display: grid;
  align-items: center;
  justify-items: flex-start;
  padding: 32px 40px;
  overflow: hidden;
  position: relative;
}

.headerImg {
  background-image: url('../../assets/loginBackground.jpg');
  background-repeat: no-repeat;
  background-position: right;
  background-size: cover;
  position: absolute;
  transform: rotate(-169.39deg);
  width: 820.69px;
  height: 380.01px;
  left: 0px;
  top: -150px;
}

.inner {
  position: relative;
  text-align: center;
  color: var(--white);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 24px;
}

.logo {
  font-size: 32px;
  line-height: 32px;
  font-weight: 700;
}

.logoDetails {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 4px;
}

.logoDetails p {
  font-size: 18px;
  margin: 0;
}

.details {
  padding: 32px 40px;
  min-height: 770px;
  position: relative;
}

.layout {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.content {
  display: flex;
  flex-direction: column;
}

.patientDetails {
  border: 1px dashed var(--gray-600);
  border-radius: 2px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 250px;
}

.patientDetails h4 {
  font-size: 11px;
  line-height: 14px;
  text-transform: uppercase;
  margin: 0 0 5px;
}

.patientDetailsItem {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 15px;
  overflow-wrap: break-word;
  word-break: break-word;
}

.patientDetailsItem svg {
  min-height: 16px;
  min-width: 16px;
}

.patientDetailsItem p {
  margin: 0;
  font-size: 16px;
  line-height: 100%;
}

.succesWrapper p {
  margin: 0;
  color: var(--gray-600);
}

.title {
  font-size: 32px;
  line-height: 32px;
  font-weight: 700;
  margin-bottom: 25px;
}

.description {
  margin-bottom: 40px;
}

.description p {
  margin: 0 0 20px;
}

.description p:last-child {
  margin-bottom: 0;
}

.listItem {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 16px;
  margin: 0 0 40px;
}

.listNumber {
  width: 30px;
  height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  background-color: #f6f6f9;
  border: 3px solid white;
}

.listDescription {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 16px;
}

.listDescription p {
  margin: 0;
}

.link {
  background-color: var(--secondary-400);
  border-radius: var(--base-border-radius);
  border: 1px solid var(--secondary-400);
  padding: 9px 20px;
  color: var(--button-navy-blue);
  text-decoration: none;
  cursor: pointer;
  transition: 0.2s all ease-in-out;
  background-color: var(--white);
  border-color: var(--secondary-50);
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  width: fit-content;
}

.code {
  font-size: 32px;
  line-height: 34px;
  font-weight: 700;
  color: var(--button-navy-blue);
}

.info {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
}

.info p {
  margin: 0;
  line-height: 110%;
}

.brandMark {
  position: absolute;
  bottom: 40px;
  right: 40px;
}

.greyBackground {
  background: var(--gray-100);
  opacity: 0.5;
  position: absolute;
  width: 1369.91px;
  height: 556.83px;
  transform: rotate(144.2deg);
  bottom: -70px;
  left: -190px;
}

.signature {
  margin: 45px 0 0;
}

.buttonDownload {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.svgDownload {
  margin-bottom: 24px;
}

.dateFormat {
  color: var(--gray-200);
}
