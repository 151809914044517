.tableContainer {
  width: 100%;
  border-spacing: 0 5px;
}

body .headerRow {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: var(--gray-400);
  text-align: left;
}

.tableRow {
  width: 100%;
  background: var(--white);
}

.tableItem {
  padding: 0;
}

.cell {
  border-right: 1px solid #e6e7ed;
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  color: var(--gray-800);
  min-height: 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 6px 10px;
  gap: 2px;
}

.tableItem:last-child > .cell,
.tableItem:nth-last-child(2) > .cell {
  border-right: none;
}

.section {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.cellGhost {
  width: 100%;
}

.loadingGhost {
  pointer-events: none;
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  background-size: 200% 100%;
  animation: 1s shine linear infinite;
  color: transparent;
}

.loadingGhost .cellGhost {
  border-right: none;
  min-height: 48px;
  width: 100%;
  margin-right: 10px;
}

.loadingGhost .cellGhost:first-child {
  min-width: 150px;
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}

td.tableItem:first-child {
  border-radius: 10px 0 0 10px;
  -moz-border-radius: 10px 0 0 10px;
  -webkit-border-radius: 10px 0 0 10px;
}

td.tableItem:last-child {
  border-radius: 0 10px 10px 0;
  -moz-border-radius: 0 10px 10px 0;
  -webkit-border-radius: 0 10px 10px 0;
  justify-content: flex-end;
}

th.headerItem {
  font-weight: 500;
}

.headerCell {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
