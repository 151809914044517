.root {
  background-color: var(--white);
  border-radius: 16px;
  padding: 16px;
  position: relative;
  display: inline-flex;
  flex-direction: row;
  gap: 30px;
  align-items: flex-start;
  transition: 0.2s all ease-in-out;
}

.name {
  font-weight: 600;
  text-decoration: none;
  color: inherit;
}

.label {
  color: var(--gray-400);
  font-weight: 400;
}

.cell {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  min-width: 100px;
  line-height: 1.2;
  gap: 5px;
}

.cell:first-child {
  min-width: 130px;
}

.cell strong {
  font-weight: bold;
}

.loadingGhost * {
  color: transparent;
}

.loadingGhost {
  min-width: 410px;
  min-height: 100px;
  pointer-events: none;
}

.loadingGhost {
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  background-size: 200% 100%;
  animation: 1s shine linear infinite;
  color: transparent;
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}

.link {
  color: inherit;
}

.datesPeriod {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
