@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100;200;300;400;500;700;800&display=swap');

:root {
  --base-font: 'Lexend', sans-serif;
  --base-font-size: 16px;
  --base-line-height: 1.5;
  --base-font-weight: 300;

  --light-gray: #828da9;
  --medium-gray: #49526a;
  --dark-gray: #303646;
  --gray-400: #9ba4ba;

  --button-navy-blue: #0a3069;

  --form-input-fill: #f3f4f7;
  --form-input-border: #e3e4ec;

  --primary-color: #0a3069;

  --base-border-radius: 8px;
  --boxes-border-radius: 16px;

  /* Color styles */
  --white: #ffffff;
  --white-shade: #f6f6f9;
  --gray-50: #f4f4f4;
  --gray-95: #F2F2F2;
  --gray-100: #e2e4eb;
  --gray-200: #ccd0dc;
  --gray-400: #9ba4ba;
  --gray-500: #828da9;
  --gray-600: #626e8e;
  --gray-700: #49526a;
  --gray-800: #303646;
  --gray-900: #050505;
  --primary-100: #cacbff;
  --primary-800: #121585;
  --secondary-50: #f3f4f7;
  --secondary-400: #6a6ef4;
  --secondary-500: #0969da;
  --error-100: #fee4e2;
  --error-400: #f97066;
  --error-500: #f04438;
  --warning-200: #fedf89;
  --success-100: #d1fadf;
  --success-400: #32d583;
  --blue-50: #d3eeff;
  --blue-400: #218bff;
  --pink-50: #fdf2fa;
  --pink-400: #f670c7;

  /* Effect styles */
  --shadow-xs: 0px 1px 2px rgba(16, 24, 40, 0.05);
}

body {
  font-family: var(--base-font);
  font-size: var(--base-font-size);
  color: var(--gray-800);
  line-height: var(--base-line-height);
  font-weight: var(--base-font-weight);
}

.container {
  max-width: 1336px;
  margin: 0 auto;
  padding: 0 20px;
  width: 100%;
}

* {
  box-sizing: border-box;
}

ul {
  padding-inline-start: 15px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.customDatePicker {
  width: 100%;
}

.customDatePicker .react-date-picker__wrapper {
  width: 100%;
  border: 1px solid var(--gray-100);
  padding: 12px 16px;
  border-radius: var(--base-border-radius);
  font-weight: 400;
  background: #fff;
}

.customDatePicker .react-date-picker__wrapper .react-date-picker__inputGroup__input:invalid {
  background: none;
}

.customDatePicker .react-date-picker__wrapper .react-date-picker__button {
  opacity: 0.5;
  transition: 0.2s all ease-in-out;
}

@media screen and (min-width: 800px) {
  .customDatePicker .react-date-picker__wrapper .react-date-picker__button:hover {
    opacity: 1;
  }
}

.react-tooltip__show {
  z-index: 1;
}

/* Custom styles for react-phone-input-2 */
.react-tel-input .form-control {
  border: 1px solid var(--gray-100);
  padding: 12px 16px 12px 54px;
  border-radius: var(--base-border-radius);
  font-weight: 400;
  width: 100%;
  transition: 0.2s all ease-in-out;
}

.react-tel-input .flag-dropdown {
  border: none;
  background: none;
}

.react-tel-input .selected-flag {
  background: none;
  border: none;
}

.country-list {
  max-width: none;
  min-width: 400px;
}

.react-tel-input .flag {
  margin-right: 8px;
}

.react-tel-input .form-control:focus {
  outline: none;
  box-shadow: none;
}
