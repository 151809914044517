.root {
  display: grid;
  grid-template-columns: 400px 1fr;
  gap: 20px;
  align-items: start;
}

.root .box {
  background-color: var(--white);
  border-radius: 16px;
  padding: 16px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;
  transition: 0.2s all ease-in-out;
}

.root:not(.locked) .box {
  box-shadow: 4px 40px 40px -40px rgba(88, 91, 227, 0.3);
}

.newTag {
  position: absolute;
  top: 16px;
  right: 16px;
}

.name {
  display: grid;
  align-items: center;
  gap: 10px;
  grid-template-columns: 60px 1fr;
}

.name h2 {
  font-size: 24px;
  margin: 0;
  word-break: break-all;
}

.image {
  background-color: var(--button-navy-blue);
  width: 60px;
  height: 60px;
  text-align: center;
  border-radius: var(--base-border-radius);
  display: grid;
  align-items: center;
  justify-items: center;
}

.locked .image {
  background-color: var(--error-400);
}

.image svg path {
  fill: var(--white);
}

.infoRows {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.infoRow {
  display: grid;
  grid-template-columns: 200px 1fr;
  gap: 10px;
}

.infoRow .label {
  color: var(--gray-400);
  line-height: 1.3;
}

.separator {
  width: 100%;
  border: none;
  background: var(--gray-100);
  height: 1px;
  margin: 0;
}

.buttons {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.locked {
  pointer-events: none;
  position: relative;
  overflow: hidden;
}

.locked:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--gray-200);
  opacity: 0.5;
}

.value {
  word-break: break-all;
}

.loadingGhost .name h2,
.loadingGhost .infoRow .label,
.loadingGhost .infoRow .value,
.loadingGhost .box .name .image {
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  background-size: 200% 100%;
  animation: 1s shine linear infinite;
  color: transparent;
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}

.shareWrapper {
  margin-top: 50px;
}

.dataExtractedFormDicom {
  text-transform: uppercase;
  color: var(--gray-400);
}
