body .resentText {
  display: flex;
  flex-direction: row!important;
  justify-content: center;
  align-items: center;

  gap: 4px;
  
  text-align: center;
}
