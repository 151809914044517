.root {
  text-align: center;
}

.root h1 {
  margin: 0;
  font-size: 80px;
}

.root h3 {
  margin: 0;
}
