.root {
  background-color: var(--primary-color);
  border-radius: var(--base-border-radius);
  border: 1px solid var(--primary-color);
  padding: 9px 20px;
  color: var(--white);
  cursor: pointer;
  transition: 0.2s all ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.root span {
  cursor: pointer;
}

.root.large {
  padding: 15px 20px;
}

.root.navy:hover {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.root.withIcon.naked:not(.loadingGhost) {
  border-color: var(--gray-200);
  /* color: var(--gray-500); */
}

.root:not(.navy):hover,
.root.withIcon.naked:hover {
  background-color: var(--primary-800);
  border-color: var(--primary-800);
  color: white;
}

.root.fullWidth {
  width: 100%;
}

.naked {
  background-color: var(--white);
  border-color: var(--secondary-50);
  color: var(--primary-color);
}

.nakedGrey {
  background-color: var(--gray-95);
  border-color: transparent;
  color: var(--primary-color);
}

.blank {
  background-color: var(--white);
  border-color: transparent;
  color: var(--primary-color);
}

.navy {
  background-color: var(--primary-800);
  border-color: var(--primary-800);
}

.root:disabled {
  background-color: var(--white);
  border-color: var(--secondary-50);
  color: var(--gray-400);
  pointer-events: none;
}

.blank:disabled {
  border-color: transparent;
}

.root.naked:disabled {
  background-color: var(--gray-50);
}

.root.withIcon {
  display: flex;
  align-items: center;
  gap: 5px;
}

.loadingGhost,
.loadingGhost:disabled {
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  background-size: 200% 100%;
  animation: 1s shine linear infinite;
  color: transparent;
  border-color: transparent;
  pointer-events: none;
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}

.loadingIconWrapper {
  width: 16px;
  height: 16px;
  position: relative;
  display: grid;
  align-content: center;
  justify-content: center;
}

.loadingIconInner {
  margin-right: 5px;
}

.loadingIconInner svg {
  float: left;
}

.inlineButton {
  background-color: transparent;
  border-radius: 0;
  border: none;
  padding: 0 0;
  text-decoration: underline;
  color: var(--secondary-400);
  cursor: pointer;
  display: inline-block;
  margin: 0 4px;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
}

.root.inlineButton:hover {
  background-color: transparent;
  border-radius: 0;
  border: none;
  color: var(--secondary-400);
}
